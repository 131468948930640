import React, { useState, useEffect } from 'react';

const Footer = () => {
    const [isVisible, setIsVisible] = useState(false);

    // Show footer when scrolled down
    useEffect(() => {
        const handleScroll = () => {
            setIsVisible(window.scrollY > 90);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <footer
            className={`fixed bottom-0 left-0 w-full bg-dark-bg text-gray-400 text-center py-4 transition-opacity duration-500 ${isVisible ? 'opacity-100' : 'opacity-0'}`}

        >
            <p className="text-sm">© 2024 Miha Štih. All rights reserved.</p>
        </footer>
    );
};

export default Footer;
