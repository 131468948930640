import React from "react";
import LandingPage from "./components/LandingPage.jsx";
import Footer from "./components/Footer.jsx";

function App() {
  return (
    <div classname="font-sans">
      <LandingPage />
      <Footer />
    </div>
  );
}

export default App;
